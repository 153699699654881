import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { getFluidImage } from '../utils/images'
import { chunkArray, getComponentClass, getComponentId } from '../utils/helpers'
import ThemeContext from '../context/ThemeContext'

const BoxGrid = ({ settings, pageContext }) => {
  const { items } = settings
  const { langSlug } = pageContext
  const rows = chunkArray(items, 3)

  const componentId = getComponentId(settings)

  return (
    <ThemeContext.Consumer>
      {color => (
        <div
          id={componentId}
          className={`box-grid bg-white ${getComponentClass(settings)}`}
        >
          {rows.map((row, rowIndex) => (
            <div className='row' key={rowIndex}>
              {row.map((item, index) => {
                const url = item.value?.url
                const title = item.value?.title
                const text = item.value?.text
                const buttonText = item.value?.buttonText
                const image = item.value?.image?.[0]

                return (
                  <div className='col-sm-4 box-item' key={index}>
                    <div className='mobile-content-wrapper'>
                      <div
                        className='box-item-top'
                        style={{ background: color }}
                      >
                        <div className='ar-inner'>
                          <strong>{title}</strong>
                          <div
                            className='box-item-text'
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                        </div>
                      </div>

                      <Link to={`/${langSlug}${url}`}>
                        <div className='box-item-bottom'>
                          <Img
                            fluid={getFluidImage({
                              cloudinary: image?.public_id,
                              ar: 1.56,
                              width: '20px',
                              maxWidth: 363,
                              widths: [320, 363]
                            })}
                            alt={image?.meta?.description || 'box grid'}
                            title={image?.meta?.description}
                            className='hover-zoom'
                            loading='lazy'
                            placeholderStyle={{ backgroundColor: 'lightgray' }}
                          />
                          <button style={{ background: color }}>
                            <strong>{buttonText}</strong>
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default BoxGrid
