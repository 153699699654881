import React from 'react'
import { getComponentClass, getComponentId } from '../utils/helpers'

const StoryHighlight = ({ settings, pageContext }) => {
  const { icon } = settings
  let { text } = settings
  const { langSlug } = pageContext

  // Search for links and add langSlug with last /
  if (langSlug && text) {
    text = text.replace(/ href="\//g, ' href="/' + langSlug)
  }

  const componentId = getComponentId(settings)

  return (
    <div
      id={componentId}
      className={`story-highlight ${icon} ${getComponentClass(settings)}`}
    >
      <div
        className='story-highlight-text'
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

export default StoryHighlight
