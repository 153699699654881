import React from 'react'
import { Link } from 'gatsby'

import {
  getComponentClass,
  getComponentId,
  splitArrayEvenly
} from '../utils/helpers'

const DestinationsButtons = ({ settings }) => {
  const { buttons } = settings
  const componentId = getComponentId(settings)
  const columns = splitArrayEvenly(buttons, 3)

  return (
    <div
      id={componentId}
      className={`destinations-list destinations-list-boxes ${getComponentClass(
        settings
      )}`}
    >
      <div className='mobile-content-wrapper'>
        <h2>Destination-infos</h2>
        <div className='row'>
          {columns.map((col, index) => (
            <div className='col-sm-4' key={index}>
              {col.map((destination, index) => {
                const title = destination.value?.title
                const url = destination.value?.url
                return (
                  <Link to={url} key={index}>
                    {title}
                  </Link>
                )
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DestinationsButtons
