import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import {
  getComponentClass,
  getComponentId,
  splitArrayEvenly,
  splitDestinationsIntoGroups,
} from "../utils/helpers"

const DestinationsList = ({ settings, pageContext }) => {
  const [parts, setParts] = useState(getPartsValue())
  const { destinations } = settings
  const { langSlug } = pageContext

  const componentId = getComponentId(settings)

  const destinationGroups = splitDestinationsIntoGroups(destinations)

  useEffect(() => {
    const handleResize = () => setParts(getPartsValue())

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const renderDestinations = destinations => {
    const columns = splitArrayEvenly(destinations, parts)

    return (
      <div className="row">
        {columns.map((col, index) => (
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2" key={index}>
            {col.map((destination, index) => (
              <Link to={langSlug + destination.slug?.value} key={index}>
                <strong>{langSlug + destination.name?.value}</strong>reisen
              </Link>
            ))}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div
      id={componentId}
      className={`destinations-list destinations-list-list ${getComponentClass(
        settings
      )}`}
    >
      <div className="destinations-list-header">
        Wählen Sie Ihre Traumdestination
      </div>
      <div className="destinations-list-content">
        {renderDestinations(destinationGroups.destinations)}
        {renderDestinations(destinationGroups.categories)}
      </div>
    </div>
  )
}

function getPartsValue() {
  let value = 0

  if (typeof window !== "undefined") {
    if (window.innerWidth < 576) {
      value = 0
    } else if (window.innerWidth < 768) {
      value = 2
    } else if (window.innerWidth < 992) {
      value = 3
    } else if (window.innerWidth < 1200) {
      value = 4
    } else if (window.innerWidth < 1400) {
      value = 5
    } else if (window.innerWidth >= 1400) {
      value = 6
    }
  }

  return value
}

export default DestinationsList
