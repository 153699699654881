import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { getComponentClass, getComponentId } from '../utils/helpers'

const FAQ = ({ settings, pageContext }) => {
  const { accordions } = settings
  const { langSlug } = pageContext
  const [active, setActive] = useState(null)
  const destinationColor = pageContext?.destination?.color?.value || 'white'

  const componentId = getComponentId(settings)

  if (!accordions) {
    return ''
  }

  let faqSchema = []
  // Create array of QA for Schema.org
  // Strip HTML from answers
  accordions.forEach(accordionItem => {
    const { accordionContent } = accordionItem.value
    accordionContent.forEach(item => {
      const title = item.value?.title
      let body = item.value?.body

      // Search for links and add langSlug with last /
      if (langSlug && body) {
        body = body.replace(/ href="\//g, ' href="/' + langSlug)
      }

      if (title && body) {
        faqSchema.push({
          '@type': 'Question',
          name: title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: body.replace(/(<([^>]+)>)/gi, '')
          }
        })
      }
    })
  })

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {`{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": ${JSON.stringify(faqSchema)}
      }`}
        </script>
      </Helmet>

      <div
        id={componentId}
        className={`faq-group ${getComponentClass(settings)}`}
      >
        {accordions.map((accordion, index) => {
          const {
            value: { title, accordionContent }
          } = accordion
          // let {
          //   value: { body }
          // } = accordion

          const isActive = active === index

          // Search for links and add langSlug with last /
          // if (langSlug) {
          //   body = body.replace(/ href="\//g, ' href="' + langSlug)
          // }

          return (
            <div
              className={`faq-item ${isActive ? 'active' : ''} container`}
              key={index}
            >
              <button
                className='faq-title'
                onClick={() => setActive(isActive ? null : index)}
                style={{ background: destinationColor, color: 'white' }}
              >
                <strong>{title}</strong>
                <span className='faq-arrow'>
                  <i className={`ki ki-angle-${isActive ? 'up' : 'down'}`}></i>
                </span>
              </button>
              <div
                className={`row faq-content-container ${
                  isActive ? 'active' : ''
                }`}
              >
                {accordionContent.map(({ value: { body, title, image } }) => (
                  <div className='faq-content col-sm-6'>
                    {image[0]?.path && (
                      <img src={image[0]?.path} alt='faq' loading='lazy' />
                    )}
                    <div style={{ width: '100%' }}>
                      <div
                        className='faq-content-title'
                        style={{ color: destinationColor }}
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                      <div
                        className='faq-content-body'
                        dangerouslySetInnerHTML={{ __html: body }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default FAQ
