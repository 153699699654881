import React from 'react'
import Img from 'gatsby-image'

import { getFluidImage } from '../utils/images'
import { getComponentClass, getComponentId } from '../utils/helpers'

const Feedbacks = ({ settings }) => {
  const { feedbacks } = settings
  const componentId = getComponentId(settings)

  return (
    <div
      id={componentId}
      className={`feedbacks ${getComponentClass(settings)}`}
    >
      {feedbacks?.map((feedback, index) => {
        const title = feedback.title?.value
        const customerName = feedback.customername?.value
        const customerLocation = feedback.customerlocation?.value
        const subTitle = `${customerName} aus ${customerLocation}`
        const content = feedback.content?.value
        const image = feedback.images?.value?.[0]

        return (
          <div className='feedback-item' key={index}>
            <div className='row'>
              <div className='col-sm-4 feedback-image mobile-content-wrapper'>
                {image && (
                  <Img
                    fluid={getFluidImage({
                      cloudinary: image?.public_id,
                      ar: 1.7
                    })}
                    alt={image?.meta?.description || 'feedback'}
                    title={image?.meta?.description}
                    loading='lazy'
                    placeholderStyle={{ backgroundColor: 'lightgray' }}
                  />
                )}
              </div>

              <div className='col-sm-8 feedback-content'>
                <div className='feedback-header'>
                  <strong>{title}</strong>
                  <div>{subTitle}</div>
                </div>
                {content && (
                  <div
                    className='feedback-body'
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Feedbacks
