import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { getComponentClass, getComponentId } from '../utils/helpers'

const AccordionGroup = ({ settings, pageContext }) => {
  const { accordions } = settings
  const { langSlug } = pageContext
  const [active, setActive] = useState(null)

  const componentId = getComponentId(settings)

  if (!accordions) {
    return ''
  }

  const renderAccordionItemTitle = (title, headingType) => {
    switch (headingType) {
      case 'h2':
        return <h2>{title}</h2>
      case 'h3':
        return <h3>{title}</h3>
      case 'h4':
        return <h4>{title}</h4>
      case 'h5':
        return <h5>{title}</h5>
      case 'h6':
        return <h6>{title}</h6>
      default:
        return <strong>{title}</strong>
    }
  }

  let faqSchema = []
  // Create array of QA for Schema.org
  // Strip HTML from answers
  accordions.forEach(accordionItem => {
    const title = accordionItem.value?.title
    let body = accordionItem.value?.body

    // Search for links and add langSlug with last /
    if (langSlug && body) {
      body = body.replace(/ href="\//g, ' href="/' + langSlug)
    }

    if (title && body) {
      faqSchema.push({
        '@type': 'Question',
        name: title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: body.replace(/(<([^>]+)>)/gi, '')
        }
      })
    }
  })

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {`{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": ${JSON.stringify(faqSchema)}
      }`}
        </script>
      </Helmet>

      <div
        id={componentId}
        className={`accordion-group ${getComponentClass(settings)}`}
      >
        {accordions.map((accordion, index) => {
          const {
            value: { title, headingType }
          } = accordion
          let {
            value: { body }
          } = accordion

          const isActive = active === index

          // Search for links and add langSlug with last /
          if (langSlug) {
            body = body.replace(/ href="\//g, ' href="' + langSlug)
          }

          return (
            <div
              className={`accordion-item ${isActive ? 'active' : ''}`}
              key={index}
            >
              <button
                className='accordion-title'
                onClick={() => setActive(isActive ? null : index)}
              >
                {title && renderAccordionItemTitle(title, headingType)}
                <span className='accordion-arrow'>
                  <i className={`ki ki-angle-${isActive ? 'up' : 'down'}`}></i>
                </span>
              </button>

              <div
                className='accordion-body'
                dangerouslySetInnerHTML={{ __html: body }}
                // style={{ display: isActive ? 'block' : 'none' }}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default AccordionGroup
