import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { truncate } from 'lodash'

import { getFluidImage } from '../../utils/images'
import ThemeContext from '../../context/ThemeContext'

import IconMapWhite from '../../assets/images/icons/icon_map_white.png'
import IconCalendarGrey from '../../assets/images/icons/icon_calendar_grey.png'

import Stars from './RatingStars'

const ProductList = ({
  endpoint,
  products,
  destinationSlug,
  langSlug,
  id,
  className
}) => {
  const isRoundtrip = endpoint === 'roundtrips'
  // console.log(products)

  return (
    <ThemeContext.Consumer>
      {color =>
        products &&
        products.length > 0 && (
          <div id={id} className={`product-list ${className}`}>
            {products.map((product, index) => {
              const { title, slug, images, price, stars } = product

              let image1 = null
              let image2 = null
              let description = null
              let location = null
              let daysAndNights = null

              if (isRoundtrip) {
                image1 = images[1]
                image2 = images[0] // map image

                if (product.highlights) {
                  description = 'Highlights: ' + product.highlights.join(', ')
                }

                if (product.from || product.to) {
                  if (product.from === product.to) {
                    location = `ab/bis ${product.from}`
                  } else {
                    location = `ab ${product.from} bis ${product.to}`
                  }
                }
                // prices = product.versions.map(v => v.prices)
                daysAndNights = `${product.days} Tage`
                if (product.nights) {
                  daysAndNights += ` / ${product.nights} Nächte`
                }
              } else {
                image1 = images[0]
                image2 = images[1]
                location = product.location

                description = product.description
              }

              description = truncate(description, {
                length: 120,
                separator: ' '
              })

              const url = '/' + langSlug + destinationSlug + '/' + slug

              return (
                <div
                  key={index}
                  className={`mobile-content-wrapper product-list-item${
                    isRoundtrip ? ' product-list-item-roundtrip' : ''
                  }`}
                >
                  <div className='row equal'>
                    <div className='col-sm-4'>
                      <div className='image'>
                        <Link to={url}>
                          <Img
                            fluid={getFluidImage({
                              cloudinary: image1?.cloudinary,
                              ar: 1.6
                            })}
                            alt={image1?.alt || 'product'}
                            title={image1?.alt}
                            loading='lazy'
                            placeholderStyle={{ backgroundColor: 'lightgray' }}
                          />
                          {isRoundtrip && (
                            <div className='days'>
                              <img
                                src={IconMapWhite}
                                width={30}
                                alt='Icon map'
                                title='Icon map'
                                loading='lazy'
                              />
                              {product.days} Tage
                            </div>
                          )}
                        </Link>
                      </div>
                    </div>

                    <div className='col-sm-4'>
                      <div className='image'>
                        <Link to={url}>
                          <Img
                            fluid={getFluidImage({
                              cloudinary: image2?.cloudinary,
                              ar: 1.6
                            })}
                            alt={image2?.alt || 'product'}
                            title={image2?.alt}
                            loading='lazy'
                            placeholderStyle={{ backgroundColor: 'lightgray' }}
                          />
                        </Link>
                      </div>
                    </div>

                    <div className='col-sm-4'>
                      <div
                        className={`product-info ${
                          isRoundtrip ? 'product-info-roundtrip' : ''
                        }`}
                      >
                        <div>
                          <div className='header'>
                            <span>
                              <Link to={url}>
                                <strong>{title}</strong>
                              </Link>
                            </span>
                            {(stars || stars !== 0) && (
                              <span className='rating'>
                                <Stars num={stars} />
                              </span>
                            )}
                            <div>
                              {price ? (
                                <>
                                  ab CHF {price}.– /{' '}
                                  <span className='price-period'>
                                    pro Person
                                  </span>
                                </>
                              ) : (
                                'Preis auf Anfrage'
                              )}
                            </div>
                          </div>

                          <div className='location'>{location}</div>
                          <div
                            className='desc'
                            dangerouslySetInnerHTML={{ __html: description }}
                          />

                          {isRoundtrip && (
                            <div className='calendar'>
                              <img
                                src={IconCalendarGrey}
                                width={20}
                                className='mr-2'
                                alt='Icon calendar'
                                title='Icon calendar'
                                loading='lazy'
                              />
                              {daysAndNights}
                            </div>
                          )}
                        </div>

                        <div className='button'>
                          <Link to={url}>
                            <button
                              className='btn-fill'
                              style={{ background: color }}
                            >
                              Details ansehen
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      }
    </ThemeContext.Consumer>
  )
}

export default ProductList
