// https://www.storyblok.com/tp/react-dynamic-component-from-json
import React, { useEffect, useState } from 'react'

import ApiData from './ApiData'
import BoxGrid from './BoxGrid'
import BoxReference from './BoxReference'
import BranchInfo from './BranchInfo'
import StoryGroup from './StoryGroup'
import StoryHighlight from './StoryHighlight'
import MapList from './MapList'
import AccordionGroup from './AccordionGroup'
import FAQ from './FAQ'
import DestinationsList from './DestinationsList'
import DestinationsButtons from './DestinationsButtons'
import html from './html'
import HSMeetings from './HSMeetings'
import form from './Form'
import Specialists from './Specialists/SpecialistsDestination'
import SpecialistsBranch from './Specialists/SpecialistsBranch'
import Events from './Events'
import Feedbacks from './Feedbacks'
import ButtonGroup from './ButtonGroup'
import Catalogs from './Catalogs'
import FormCamperIframe from './FormCamperIframe'
import MapGroup from './MapGroup'
import HubSpotForm from './HubSpotForm'
import TwoColumnContent from './TwoColumnContent'
import ImageSection from './ImageSection'
import Teaser from './Teaser'
import OurLocations from './OurLocations'
import { getDestinationLabel } from '../utils/helpers'
import { useLocation } from '@reach/router'
import { checkIsKnechtHome } from '../utils/checkIsKnechtHome'

const Components = {
  ApiData,
  BoxGrid,
  BoxReference,
  BranchInfo,
  StoryGroup,
  StoryHighlight,
  MapList,
  AccordionGroup,
  FAQ,
  DestinationsList,
  DestinationsButtons,
  html,
  HSMeetings,
  form,
  Specialists,
  SpecialistsBranch,
  ButtonGroup,
  Catalogs,
  Events,
  Feedbacks,
  FormCamperIframe,
  MapGroup,
  TwoColumnContent,
  HubSpotForm,
  ImageSection,
  Teaser,
  OurLocations
}

const skipComponents = ['StructuredDataJobPosting']

export default (element, pageContext, index) => {
  const { component: componentName, settings, additionalSettings } = element
  const { pathname, hash } = useLocation()
  const [width, setWidth] = useState()

  const handleWindowSizeChange = () => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])

  const isMobile = width <= 768

  useEffect(() => {
    setTimeout(() => {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        const position = element.getBoundingClientRect()
        const offset = isMobile ? 0 : 220
        if (typeof window !== 'undefined') {
          window.scrollTo(position.left, position.top + window.scrollY - offset)
        }
      }
    }, 0)
  }, [pathname, hash, isMobile]) // do this on route change

  if (skipComponents.indexOf(componentName) !== -1) {
    // skip execution for not visible components - processed for SEO, ...
    return null
  }

  let bgWhite = true
  const componentsWithoutBgWhite = ['BoxReference']
  if (
    (componentName === 'StoryGroup' && settings.storyType === 'basic') 
    || (componentsWithoutBgWhite.includes(componentName))
  ) {
    bgWhite = false
  }

  let hideBoxShadow = false
  const componentsWithoutShadow = ['form', 'HubSpotForm', 'BoxReference']
  if (componentsWithoutShadow.includes(componentName)) {
    hideBoxShadow = true
  }

  if (componentName === 'form') {
    // pre-process destination name/label
    const { isRootPage, knechtDestination, destination, url } = pageContext
    const currentPageUrl = url || ''
    const mainDestinationMenuItems = knechtDestination?.menuItems?.value || []
    const destinationMenuItems = destination?.menuItems?.value || []

    const destName = getDestinationLabel(
      currentPageUrl,
      isRootPage,
      mainDestinationMenuItems,
      destinationMenuItems
    )

    settings.destinationLabel = destName
  }

  if (componentName === 'HubSpotForm') {
    // pre-process destination name/label
    const { isRootPage, knechtDestination, destination, url } = pageContext
    const currentPageUrl = url || ''
    const mainDestinationMenuItems = knechtDestination?.menuItems?.value || []
    const destinationMenuItems = destination?.menuItems?.value || []

    const destName = getDestinationLabel(
      currentPageUrl,
      isRootPage,
      mainDestinationMenuItems,
      destinationMenuItems
    )

    additionalSettings.destinationlabel = destName
  }

  // component does exist
  if (typeof Components[componentName] !== 'undefined') {
    const isKnechtHome = checkIsKnechtHome(pageContext?.page)

    return (
      <div
        className={`content-element ${componentName} ${bgWhite ? 'bg-white' : ''} ${hideBoxShadow ? 'no-box-shadow' : ''}`}
        key={index}
        style={{
          ...(isKnechtHome &&
            componentName === 'DestinationsList' && {
              ...(width >= 1400
                ? { position: 'absolute', top: '-430px' }
                : { marginBottom: -50 }),
              width: '100%',
            }),
        }}
      >
        {(process.env.BENV === 'dev' || process.env.BENV === 'dev_kira') && (
          <div className='dev'>
            <div className='content-element-component'>{element.component}</div>
          </div>
        )}
        {React.createElement(Components[componentName], {
          settings,
          additionalSettings,
          pageContext
        })}
      </div>
    )
  }

  // component doesn't exist yet
  return React.createElement(
    () => (
      <div style={{ background: 'red', color: '#fff', padding: 35 }}>
        The component <strong>{componentName}</strong> has not been created yet.
      </div>
    ),
    { key: index }
  )
}
