import React from 'react'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

import { getFluidImage } from '../utils/images'
import btnContact from '../assets/images/misc/CTA-Button-Fragen-sie-uns.svg'
import { getComponentClass, getComponentId } from '../utils/helpers'

const StoryGroup = ({ settings, pageContext }) => {
  const { header, stories, storyType, pageLink } = settings
  const { langSlug } = pageContext

  const componentId = getComponentId(settings)

  return (
    <div
      id={componentId}
      className={`story-group type-${storyType} ${
        storyType !== 'basic' ? 'bg-white' : ''
      } ${getComponentClass(settings)}`}
    >
      {header && <h2>{header}</h2>}

      {stories.map((story, index) => {
        let text = story.value?.text
        const image = story.value?.image?.[0]

        // Search for links and add langSlug with last /
        if (langSlug && text) {
          text = text.replace(/ href="\//g, ' href="/' + langSlug)
        }

        const imageElement = (
          <Img
            fluid={getFluidImage({
              cloudinary: image?.public_id,
              ar: 1.7
            })}
            alt={image?.meta?.description || 'story group'}
            title={image?.meta?.description}
            loading='lazy'
            placeholderStyle={{ backgroundColor: 'lightgray' }}
          />
        )

        switch (storyType) {
          case 'basic':
            return (
              <div className='story-item' key={index}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            )

          case 'image-big':
            return (
              <div className='story-item' key={index}>
                {imageElement}
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            )

          case 'image-small':
            return (
              <div className='story-item' key={index}>
                <div className='row'>
                  <div className='col-sm-8'>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                  <div className='col-sm-4'>{imageElement}</div>
                </div>
              </div>
            )

          default:
            return ''
        }
      })}

      {pageLink && (
        <Link to={`/${langSlug}${pageLink}`}>
          <img
            className='story-group-btn'
            alt='fragen sie uns'
            title='fragen sie uns'
            src={btnContact}
            loading='lazy'
          />
        </Link>
      )}
    </div>
  )
}

export default StoryGroup
