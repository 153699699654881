import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import { uniqBy, sortBy, cloneDeep } from 'lodash'
import { transparentize } from 'polished'
import axios from 'axios'

import ThemeContext from '../context/ThemeContext'
import { getFluidImage } from '../utils/images'
import HubSpotForm from './HubSpotForm'
import Form from './Form' // fallback
import Modal from './Modal'
import {
  getComponentClass,
  getComponentId,
  getDestinationLabel
} from '../utils/helpers'

const envFile = require('../../env')
const env = envFile.env[process.env.BENV || 'prod']

const Catalogs = ({ settings, pageContext }) => {
  const catalogElements = settings?.catalogElements
  const form = settings?.form
  const translatedCatalogs = settings?.translatedCatalogs

  const {
    isRootPage,
    url,
    knechtDestination,
    destination,
    hsForms
  } = pageContext
  const currentPageUrl = url || ''
  const mainDestinationMenuItems = knechtDestination?.menuItems?.value || []
  const destinationMenuItems = destination?.menuItems?.value || []

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentCatalog, setCurrentCatalog] = useState(null)
  const [currentOnlineCatalogUrl, setCurrentOnlineCatalogUrl] = useState(null)
  const [currentOnlineCatalogUrlFr, setCurrentOnlineCatalogUrlFr] = useState(
    null
  )
  const [catalogLangDefaultValue, setCatalogLangDefaultValue] = useState(
    'Deutsch'
  ) // null
  const [shippingBranchDefaultValue, setShippingBranchDefaultValue] = useState(
    null
  )
  const [processedForm, setProcessedForm] = useState(form) // fallback
  const [hover, setHover] = useState(false)
  const [hover2, setHover2] = useState(false)
  const [catalogsData, setCatalogsData] = useState([])
  const [dynamicFormValues, setDynamicFormValues] = useState({}) // fallback
  const [hideFormFields, setHideFormFields] = useState([])

  const isHubSpotForm = form?.snippet && form.snippet ? true : false // fallback

  const componentId = getComponentId(settings) || 'kataloge'

  const getApiData = async () => {
    const url = `${env.cockpit.url}/api/collections/get/catalogs?token=${env.cockpit.accessTokenCatalogs}`
    try {
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  const toggleModal = catalogName => {
    setModalIsOpen(!modalIsOpen)
    setCurrentCatalog(catalogName)

    processFormFields(catalogName)
    // temporary be sure that it will match the following condition
    updateOnlineCatalogUrl(catalogName, 'empty') // catalogLangDefaultValue
  }

  useEffect(() => {
    getApiData().then(data => {
      setCatalogsData(data)
    })

    // fallback
    if (!isHubSpotForm) {
      // get form fields default values
      if (form?.fields) {
        form.fields.forEach(field => {
          if (field.name === 'catalogLang') {
            setCatalogLangDefaultValue(field?.options?.initialValue)
          } else if (field.name === 'shippingFromBranch') {
            setShippingBranchDefaultValue(field?.options?.default)
          }
        })
      }
    }
  }, [form, isHubSpotForm])
  // }, [])

  if (!catalogElements?.length) {
    return ''
  }

  const uniqueCatalogs = sortBy(
    uniqBy(catalogElements, 'value.name.value'),
    'value.name.value'
  )

  const updateOnlineCatalogUrl = (catalogName, catalogLang) => {
    const catalog = uniqueCatalogs?.filter(element => {
      return element.value?.name?.value === catalogName
    })

    let url = null
    if (catalog && catalog['0']) {
      const allCatalogData = catalogsData['entries'].filter(cat => {
        return cat?._id === catalog['0']?.cockpitId
      })

      url = catalog['0'].value?.url?.value

      // set the DE online catalog URL
      setCurrentOnlineCatalogUrl(url)

      // temporary be sure that it will match the following condition
      let urlFr = null
      if (
        catalogLang &&
        catalogLang !== 'Deutsch' &&
        allCatalogData &&
        allCatalogData['0']
      ) {
        if (
          allCatalogData['0'].hasOwnProperty('catalogElements_fr') &&
          allCatalogData['0']?.catalogElements_fr &&
          allCatalogData['0'].catalogElements_fr.length > 0
        ) {
          const catalogFr = allCatalogData['0'].catalogElements_fr.filter(
            el => {
              return el.value?.name === catalogName
            }
          )

          if (catalogFr && catalogFr['0']) {
            // use the french catalog url
            urlFr = catalogFr['0']?.value?.url
          }
        }
      }

      // set the FR online catalog URL
      setCurrentOnlineCatalogUrlFr(urlFr)
    }

    // fallback
    if (!isHubSpotForm) {
      const tmpDynamicFormValues = {
        ...dynamicFormValues,
        onlineCatalogUrl: url
      }
      setDynamicFormValues(tmpDynamicFormValues)
    }
  }

  const processFormFields = catalogName => {
    if (!translatedCatalogs.includes(catalogName)) {
      // hide language radio field if catalog is not translated
      if (!hideFormFields.includes('cataloglanguage')) {
        const updatedArray = [...hideFormFields, 'cataloglanguage']
        setHideFormFields(updatedArray)
      }
    } else {
      const filteredArray = hideFormFields.filter(
        elem => elem !== 'cataloglanguage'
      )
      setHideFormFields(filteredArray)
    }

    // fallback
    if (!isHubSpotForm) {
      let adjustedForm = cloneDeep(form)
      if (!translatedCatalogs.includes(catalogName)) {
        // hide language radio field if catalog is not translated
        let adjustedFormFields = adjustedForm.fields.map(field => {
          if (field.name === 'catalogLang') {
            let hiddenField = {
              type: 'hiddenField',
              options: {
                class: 'col-sm-12',
                row: 'end'
              }
            }

            let adjustedField = {
              ...field,
              ...hiddenField
            }

            return adjustedField
          }

          return field
        })

        adjustedForm.fields = adjustedFormFields
      }

      setProcessedForm(adjustedForm)
    }
  }

  // fallback
  const handleOnChange = event => {
    // handle catalog lang change for getting the correct online url
    if (
      event?.target?.name === 'catalogLanguage' ||
      event?.target?.name === 'catalogLang'
    ) {
      updateOnlineCatalogUrl(currentCatalog, event?.target?.value)
    }
  }

  const getDestinationName = () => {
    return getDestinationLabel(
      currentPageUrl,
      isRootPage,
      mainDestinationMenuItems,
      destinationMenuItems
    )
  }

  return (
    <ThemeContext.Consumer>
      {color => (
        <>
          <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal}>
            {isHubSpotForm && (
              <HubSpotForm
                settings={form}
                // additionalSettings={{ destination: destination }}
                defaultValues={{
                  catalogname: currentCatalog,
                  onlinecatalogurl: currentOnlineCatalogUrl,
                  onlinecatalogurl_fr: currentOnlineCatalogUrlFr,
                  shippingfrombranch: shippingBranchDefaultValue, // shippingBranchDefaultValue || 'Windisch',
                  catalogLang: catalogLangDefaultValue, // catalogLangDefaultValue || 'Deutsch'
                  cataloglanguage: catalogLangDefaultValue, // catalogLangDefaultValue || 'Deutsch'
                  destinationlabel: getDestinationName()

                  // recipients[] for this form are stored in its' settings in CMS
                }}
                stringVars={{ subHeader: currentCatalog }}
                hideFormFields={hideFormFields}
                allHsForms={hsForms}
                // handleOnChange={handleOnChange}
              />
            )}
            {/* fallback */}
            {!isHubSpotForm && (
              <Form
                settings={{ formData: processedForm }}
                defaultValues={{
                  catalogName: currentCatalog,
                  onlineCatalogUrl: currentOnlineCatalogUrl,
                  shippingFromBranch: shippingBranchDefaultValue, // shippingBranchDefaultValue || 'Windisch',
                  catalogLang: catalogLangDefaultValue, // catalogLangDefaultValue || 'Deutsch'
                  destinationLabel: getDestinationName()
                  // recipients[] for this form are stored in its' settings in CMS
                }}
                stringVars={{ subHeader: currentCatalog }}
                handleOnChange={handleOnChange}
                dynamicFormValues={dynamicFormValues}
              />
            )}
          </Modal>

          <div
            id={componentId}
            className={`catalogs ${getComponentClass(settings)}`}
          >
            <h2>Kataloge</h2>
            <h3>Online blättern oder bestellen</h3>
            <div className='catalog-items'>
              {uniqueCatalogs?.map((element, index) => {
                const url = element.value?.url?.value
                const catalogName = element.value?.name?.value

                const image = element.value?.image?.value?.[0]

                return (
                  <div className='catalog-item' key={index}>
                    <Img
                      fluid={getFluidImage({
                        cloudinary: image?.public_id,
                        ar: 0.7
                      })}
                      alt={image?.meta?.description || 'catalog cover'}
                      title={image?.meta?.description}
                      loading='lazy'
                      placeholderStyle={{ backgroundColor: 'lightgray' }}
                    />
                    <div className='catalog-buttons'>
                      <button
                        className='catalog-btn catalog-order'
                        onClick={() => toggleModal(catalogName)}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        style={{
                          background: hover && transparentize(0.3, color)
                        }}
                      >
                        Katalog bestellen
                      </button>
                      <a
                        href={url}
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                        className='catalog-btn catalog-view'
                        onMouseEnter={() => setHover2(true)}
                        onMouseLeave={() => setHover2(false)}
                        style={{
                          background: hover2 && transparentize(0.3, color)
                        }}
                      >
                        Online blättern
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  )
}

export default Catalogs
