import React from 'react'
import Img from 'gatsby-image'
import { format, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'

import { getFluidImage } from '../utils/images'
import {
  germanTimeFormat,
  getComponentClass,
  getComponentId
} from '../utils/helpers'
import ThemeContext from '../context/ThemeContext'

const Events = ({ settings }) => {
  const { events } = settings
  const componentId = getComponentId(settings)

  return (
    <ThemeContext.Consumer>
      {color => (
        <div
          id={componentId}
          className={`events ${getComponentClass(settings)}`}
        >
          {events?.map((event, index) => {
            const title = event.title?.value
            const text = event.text?.value
            const date = event.date?.value
            const time = event.time?.value
            const location = event.location?.value
            const url = event.url?.value
            const image = event.image?.value?.[0]

            return (
              <div className='event-item' key={index}>
                <div className='row'>
                  <div className='col-md-6'>
                    <Img
                      fluid={getFluidImage({
                        cloudinary: image?.public_id,
                        ar: 1.7
                      })}
                      alt={image?.meta?.description || 'event'}
                      title={image?.meta?.description}
                      loading='lazy'
                      placeholderStyle={{ backgroundColor: 'lightgray' }}
                    />
                  </div>

                  <div className='col-md-6'>
                    <h3>{title}</h3>
                    {date && (
                      <p>
                        {format(parseISO(date), 'cccc, d. MMMM yyyy', {
                          locale: de
                        })}
                      </p>
                    )}
                    {time && <p>Beginn: {germanTimeFormat(time)} Uhr</p>}

                    {location && (
                      <p>
                        <strong>Ort: {location}</strong>
                      </p>
                    )}
                    {text && <div dangerouslySetInnerHTML={{ __html: text }} />}

                    {url && (
                      <a
                        href={url}
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                      >
                        <button
                          className='btn-fill btn-fill'
                          style={{ background: color }}
                        >
                          Anmeldung
                        </button>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default Events
