import React, { useEffect } from 'react'
import { getComponentClass, getComponentId } from '../utils/helpers'
// import { Link } from 'gatsby'

const HSMeetings = ({ settings }) => {
  const { person } = settings
  const componentId = getComponentId(settings)

  useEffect(() => {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    document.getElementsByTagName('head')[0].appendChild(script)
  })

  if (!person) {
    return false
  }

  return (
    <div
      id={componentId}
      className={`hs-meetings html ${getComponentClass(settings)}`}
      style={{ padding: '2rem' }}
    >
      <div
        class='meetings-iframe-container'
        data-src={'https://meetings.hubspot.com/' + person + '?embed=true'}
      ></div>
    </div>
  )
}

export default HSMeetings
