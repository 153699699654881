import React from 'react'
import { getComponentClass, getComponentId } from '../utils/helpers'

const TwoColumnContent = ({ settings, pageContext }) => {
  console.log('settings', settings)
  const { contents } = settings
  // const { headline, content, image, imagePosition, secondHeadline, secondContent, secondImage, secondImagePosition } = settings
  const destinationColor = pageContext?.destination?.color?.value || 'inherit'

  const componentId = getComponentId(settings)

  return (
    <div
      id={componentId}
      className={`two-column-content-container ${getComponentClass(settings)}`}
    >
      {contents?.map(column => {
        const {
          headline,
          content,
          image,
          imagePosition,
          secondHeadline,
          secondContent,
          secondImage,
          secondImagePosition
        } = column?.value || {}
        console.log('column', column)
        return (
          <>
            <div className={`two-column-content-column ${imagePosition}`}>
              {image && image[0]?.path && (
                <div className={`image-wrapper`}>
                  <img
                    src={image[0]?.path}
                    alt='content visualization'
                    loading='lazy'
                  />
                </div>
              )}
              <div className='content-wrapper'>
                {headline && (
                  <div
                    className='headline h2'
                    style={{ color: destinationColor }}
                  >
                    {headline}
                  </div>
                )}
                {content && (
                  <div
                    className='content'
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                )}
              </div>
            </div>
            <div className={`two-column-content-column ${secondImagePosition}`}>
              {secondImage && secondImage[0]?.path && (
                <div className={`image-wrapper`}>
                  <img
                    src={secondImage[0]?.path}
                    alt='content visualization'
                    loading='lazy'
                  />
                </div>
              )}
              <div className='content-wrapper'>
                {secondHeadline && (
                  <div
                    className='headline h2'
                    style={{ color: destinationColor }}
                  >
                    {secondHeadline}
                  </div>
                )}
                {content && (
                  <div
                    className='content'
                    dangerouslySetInnerHTML={{ __html: secondContent }}
                  />
                )}
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}

export default TwoColumnContent
