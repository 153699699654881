import React from 'react'

import Iframe from 'react-iframe'
// import { getComponentClass, getComponentId } from '../utils/helpers'
import { getComponentClass } from '../utils/helpers'

const FormCamperIframe = ({ settings }) => {
  // const componentId = getComponentId(settings)

  return (
    <Iframe
      src='https://www.tuicamper.com/ibe/?agk=ifl_knecht&tuian=060029&clerkNo=3392'
      className={`iframe-tui-kemper ${getComponentClass(settings)}`}
      id='iframeTuiKemper'
      // id={componentId}
      display='initial'
      position='relative'
      frameBorder='0'
      scrolling='no'
    />
  )
}

export default FormCamperIframe
