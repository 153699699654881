import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
// import Img from 'gatsby-image'
import { getComponentClass, getComponentId } from '../utils/helpers'
// import { getFluidImage } from '../utils/images'

const Teaser = ({ settings, pageContext }) => {
  const { langSlug } = pageContext
  const destinationColor = pageContext?.destination?.color?.value || 'white'
  const destinationSlug = pageContext?.destination?.slug?.value

  const componentId = getComponentId(settings)

  const {
    title,
    text,
    image,
    imagePosition,
    buttonLabel,
    buttonInternalLink,
    buttonExternalLink,
    isExternalLink
  } = settings

  const { allCockpitPages } = useStaticQuery(
    graphql`
      query {
        allCockpitPages {
          edges {
            node {
              lang
              cockpitId
              level2 {
                value
              }
              level3 {
                value
              }
              level4 {
                value
              }
            }
          }
        }
      }
    `
  )

  const teaserImage = image[0] || null
  const hasLink = buttonInternalLink || buttonExternalLink ? true : false

  const filteredPages = allCockpitPages?.edges.filter(({ node }) => {
    return node?.cockpitId === buttonInternalLink?._id
  })

  const currentPage =
    filteredPages && filteredPages.length > 0 ? filteredPages[0] : null

  let internalUrl = ''
  if (currentPage) {
    const {
      node: { level2, level3, level4 }
    } = currentPage

    const urlParts = [
      destinationSlug,
      level2 && level2.value,
      level3 && level3.value,
      level4 && level4.value
      // level5 && level5.value
    ]

    const envFile = require('../../env')
    const env = envFile.env[process.env.BENV || 'prod']
    // destination 'knecht' is homepage, remove it from URL
    if (urlParts[0] === env.homepage_destination) {
      urlParts.shift()
    }

    internalUrl = '/' + urlParts.filter(Boolean).join('/')

    if (langSlug) {
      internalUrl = `/${langSlug}${internalUrl}`
    }
  }

  return (
    <>
      <div id={componentId} className={`teaser ${getComponentClass(settings)}`}>
        <div className={`teaser-content-wrapper image-${imagePosition}`}>
          <div className='image-wrapper'>
            {/* {teaserImage && (
              <Img
                fluid={getFluidImage({
                  cloudinary: teaserImage?.public_id,
                  ar: 1.55,
                  maxWidth: 363,
                  widths: [320, 363]
                })}
                alt={teaserImage?.meta?.description || 'teaser'}
                title={teaserImage?.meta?.description}
                loading='lazy'
                placeholderStyle={{ backgroundColor: 'lightgray' }}
              />
            )} */}
            {teaserImage && (
              <img src={teaserImage?.path} alt='teaser' loading='lazy' />
            )}
          </div>
          <div className='content-wrapper'>
            {title && <div className='title'>{title}</div>}
            {text && (
              <div
                className='text'
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
            {buttonLabel && hasLink && (
              <div className='button-wrapper'>
                {!isExternalLink && (
                  <Link
                    to={`${internalUrl}`}
                    style={{ background: destinationColor, color: 'white' }}
                  >
                    {buttonLabel}
                  </Link>
                )}
                {isExternalLink && (
                  <a
                    href={buttonExternalLink}
                    style={{ background: destinationColor, color: 'white' }}
                  >
                    {buttonLabel}
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Teaser
