import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import ThemeContext from '../context/ThemeContext'
import { getFluidImage } from '../utils/images'
import { chunkArray, getComponentClass, getComponentId } from '../utils/helpers'

// https://kne.webgarage.ch/amerikareisen/mietwagen/
const MapGroup = ({ settings, pageContext }) => {
  const { maps } = settings
  const { langSlug } = pageContext

  const componentId = getComponentId(settings)

  const rows = chunkArray(maps, 3)

  return (
    <ThemeContext.Consumer>
      {color => (
        <div
          id={componentId}
          className={`map-group ${getComponentClass(settings)}`}
        >
          {rows.map((row, index) => (
            <div className='row equal' key={index}>
              {row.map((map, index) => {
                const image = map.value?.image?.[0]
                const header = map.value?.header
                const text = map.value?.text
                const url = map.value?.url

                return (
                  <div className='col-sm-4' key={index}>
                    <div className='map-group-item mobile-content-wrapper'>
                      <div className='map-group-item-image'>
                        <Link to={`/${langSlug}${url}`}>
                          <Img
                            fluid={getFluidImage({
                              cloudinary: image?.public_id,
                              ar: 1.7
                            })}
                            alt={image?.meta?.description || 'map cover'}
                            title={image?.meta?.description}
                            className='hover-zoom'
                            loading='lazy'
                            placeholderStyle={{ backgroundColor: 'lightgray' }}
                          />
                        </Link>
                      </div>

                      <div className='map-group-item-text'>
                        {header && (
                          <strong className='map-group-item-header'>
                            {header}
                          </strong>
                        )}
                        {text && (
                          <div className='map-group-item-body'>{text}</div>
                        )}
                      </div>

                      <div className='map-group-item-link'>
                        <Link to={`/${langSlug}${url}`}>
                          <button
                            className='btn-fill'
                            style={{ background: color }}
                          >
                            Angebot ansehen
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default MapGroup
