import React from 'react'

import Map from './Map'
import { getComponentClass, getComponentId } from '../utils/helpers'

const BranchInfo = ({ settings }) => {
  const { info1, info2, info3, info4, location } = settings
  const mapCenter = [location?.lat, location?.lng]

  const componentId = getComponentId(settings)

  return (
    <div
      id={componentId}
      className={`branch-info bg-white ${getComponentClass(settings)}`}
    >
      <div className='row'>
        <div className='col-md-6 branch-info-texts'>
          <div className='row'>
            <div className='col-md-6'>
              <div
                className='branch-info-text'
                dangerouslySetInnerHTML={{ __html: info1 }}
              ></div>
            </div>

            <div className='col-md-6'>
              <div
                className='branch-info-text'
                dangerouslySetInnerHTML={{ __html: info2 }}
              ></div>
            </div>
          </div>

          {(info3 || info4) && (
            <div className='row'>
              <div className='col-md-6'>
                <div
                  className='branch-info-text'
                  dangerouslySetInnerHTML={{ __html: info3 }}
                ></div>
              </div>
              <div className='col-md-6'>
                <div
                  className='branch-info-text'
                  dangerouslySetInnerHTML={{ __html: info4 }}
                ></div>
              </div>
            </div>
          )}
        </div>

        <div className='col-md-6 branch-info-map'>
          <div>
            <strong>Standort</strong>
            <Map center={mapCenter} zoom={13} height={360} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BranchInfo
