import React from 'react'
import Img from 'gatsby-image'

import { getFluidImage } from '../utils/images'
import { getComponentClass, getComponentId } from '../utils/helpers'

const ImageSection = ({ settings }) => {
  const { title, content, items } = settings

  const componentId = getComponentId(settings)

  return (
    <div
      id={componentId}
      className={`image-section bg-white ${getComponentClass(settings)}`}
    >
      {title && <h2>{title}</h2>}

      {content && (
        <div
          className='image-section-content'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}

      <div className='image-section-items'>
        {items.map((item, index) => {
          let url = item?.value?.url
          const image = item?.value?.image?.[0]

          const imageElement = (
            <Img
              fluid={getFluidImage({
                cloudinary: image?.public_id,
                ar: 1.7
              })}
              alt={image?.meta?.description || 'section cover'}
              title={image?.meta?.description}
              loading='lazy'
              placeholderStyle={{ backgroundColor: 'lightgray' }}
            />
          )

          if (url) {
            return (
              <a
                href={url}
                className='image-item'
                key={index}
                aria-label='image item'
                rel='noopener noreferrer nofollow'
                target='_blank'
              >
                {imageElement}
              </a>
            )
          } else {
            return (
              <div className='image-item' key={index}>
                {imageElement}
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default ImageSection
