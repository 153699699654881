import React, { useState } from 'react'
import Img from 'gatsby-image'

import ThemeContext from '../../context/ThemeContext'
import {
  chunkArray,
  getComponentClass,
  getComponentId,
  getDestinationLabel
} from '../../utils/helpers'
import { getFluidImage } from '../../utils/images'
import Modal from '../Modal'
import HubSpotForm from '../HubSpotForm'
import Form from '../Form' // fallback

const SpecialistsBranch = ({ settings, pageContext }) => {
  const { specialistsBranch: specialists, branch, form } = settings
  const componentId = getComponentId(settings)
  const rows = chunkArray(specialists, 3)

  const {
    isRootPage,
    knechtDestination,
    destination,
    url,
    hsForms
  } = pageContext
  const currentPageUrl = url || ''
  const mainDestinationMenuItems = knechtDestination?.menuItems?.value || []
  const destinationMenuItems = destination?.menuItems?.value || []

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentSpecialist, setCurrentSpecialist] = useState(null)

  const isHubSpotForm = form?.snippet && form.snippet ? true : false // fallback

  const toggleModal = specialist => {
    setModalIsOpen(!modalIsOpen)
    setCurrentSpecialist(specialist)
  }

  const getDestinationName = () => {
    return getDestinationLabel(
      currentPageUrl,
      isRootPage,
      mainDestinationMenuItems,
      destinationMenuItems
    )
  }

  return (
    <ThemeContext.Consumer>
      {color => (
        <div
          id={componentId}
          className={`specialists specialists-branch ${getComponentClass(
            settings
          )}`}
        >
          <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal}>
            {isHubSpotForm && (
              <HubSpotForm
                settings={form}
                additionalSettings={{ destination: destination }}
                defaultValues={{
                  recipients: [currentSpecialist?.email?.value].join(','),
                  destinationlabel: getDestinationName(),
                  specialistname: currentSpecialist?.name?.value
                }}
                stringVars={{
                  header: currentSpecialist?.name?.value,
                  subHeader: currentSpecialist?.email?.value,
                  successMessage: currentSpecialist?.name?.value
                }}
                allHsForms={hsForms}
              />
            )}
            {!isHubSpotForm && (
              <Form
                settings={{ formData: form, destination: destination }}
                defaultValues={{
                  recipients: [currentSpecialist?.email?.value].join(','),
                  destinationLabel: getDestinationName(),
                  specialistName: currentSpecialist?.name?.value
                }}
                stringVars={{
                  header: currentSpecialist?.name?.value,
                  subHeader: currentSpecialist?.email?.value,
                  successMessage: currentSpecialist?.name?.value
                }}
              />
            )}
          </Modal>

          <div className='specialists-group'>
            <div className='specialists-group-title'>
              <strong>Unser Spezialisten-Team {branch}</strong>
            </div>

            {rows.map((row, index) => (
              <div key={index} className='row'>
                {row.map((specialist, index) => {
                  const name = specialist.name?.value
                  const phone = specialist.phonenumber?.value
                  const position = specialist.position?.value
                  const email = specialist.email?.value
                  const image = specialist.picture?.value?.[0]

                  return (
                    <div key={index} className='col-sm-4'>
                      <div className='mobile-content-wrapper'>
                        <div className='specialist-item'>
                          <div className='specialist-item-contact'>
                            <Img
                              fluid={getFluidImage({
                                cloudinary: image?.public_id,
                                ar: 1.6
                              })}
                              alt={
                                image?.meta?.description || 'specialists branch'
                              }
                              title={image?.meta?.description}
                              className='specialist-image'
                              loading='lazy'
                              placeholderStyle={{
                                backgroundColor: 'lightgray'
                              }}
                            />

                            <div className='specialist-name'>{name}</div>
                            <div className='specialist-position'>
                              {position}
                            </div>
                            <div className='specialist-number'>{phone}</div>

                            {email && (
                              <div>
                                <button
                                  className='btn-fill btn-fill mt-2'
                                  onClick={() => toggleModal(specialist)}
                                  style={{ background: color }}
                                >
                                  E-Mail
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default SpecialistsBranch
