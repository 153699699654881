import React from 'react'
// import { Link } from 'gatsby'

import ProductList from './Product/ProductList'
import { getComponentClass, getComponentId } from '../utils/helpers'

const ApiData = ({ settings, pageContext }) => {
  const { endpoint, filters, products, destinationSlug } = settings
  const { langSlug, destination } = pageContext

  let sanitisedDestinationSlug = destinationSlug
  if (destinationSlug !== destination?.slug?.value) {
    sanitisedDestinationSlug = destination.slug.value
  }

  const componentId = getComponentId(settings)
  // TODO: remove filters div

  return (
    <>
      {process.env.BENV === 'dev' && (
        <div
          style={{
            background: 'red',
            color: 'white',
            padding: 25
          }}
        >
          <strong>endpoint:</strong> {endpoint} <br />
          <strong>filters:</strong> {filters} <br />
        </div>
      )}

      <ProductList
        id={componentId}
        className={`${getComponentClass(settings)}`}
        products={products}
        endpoint={endpoint}
        langSlug={langSlug}
        destinationSlug={sanitisedDestinationSlug}
      />
    </>
  )
}

export default ApiData
