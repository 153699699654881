import React from 'react'
import Img from 'gatsby-image'

import { getFluidImage } from '../utils/images'
import { getComponentClass, getComponentId } from '../utils/helpers'

const MapList = ({ settings, pageContext }) => {
  let { list1, list2 } = settings
  const { langSlug } = pageContext
  const map = settings.map?.length && settings.map[0]
  const image1 = settings.image1?.length && settings.image1[0]
  const image2 = settings.image2?.length && settings.image2[0]

  const componentId = getComponentId(settings)

  // console.log({ list, map, image1, image2 })

  // Search for links and add langSlug with last /
  if (langSlug && list1) {
    list1 = list1.replace(/ href="\//g, ' href="/' + langSlug)
  }
  if (langSlug && list2) {
    list2 = list2.replace(/ href="\//g, ' href="/' + langSlug)
  }

  const hasTwoLists = list1 && list2

  return (
    <div
      id={componentId}
      className={`map-list row ${getComponentClass(settings)}`}
    >
      <div className='col-sm-4 map-list-left'>
        <div className='row'>
          <div className={`col-xs-${hasTwoLists ? '6' : '12'}`}>
            <div
              className='map-list-items'
              dangerouslySetInnerHTML={{ __html: list1 }}
            />
          </div>
          {hasTwoLists && (
            <div className='col-xs-6'>
              <div
                className='map-list-items'
                dangerouslySetInnerHTML={{ __html: list2 }}
              />
            </div>
          )}
        </div>
      </div>

      <div className='col-sm-8 map-list-right'>
        <div className='map-list-map'>
          {map && (
            <Img
              fluid={getFluidImage({
                cloudinary: map.public_id,
                ar: 1.7
              })}
              alt={map.meta?.description || 'map list coover'}
              title={map.meta?.description}
              loading='lazy'
              placeholderStyle={{ backgroundColor: 'lightgray' }}
            />
          )}
        </div>

        <div className='map-list-images'>
          <div className='row'>
            <div className='col-xs-6'>
              {image1 && (
                <Img
                  fluid={getFluidImage({
                    cloudinary: image1.public_id,
                    ar: 1.7
                  })}
                  alt={image1.meta?.description || 'map list cover'}
                  title={image1.meta?.description}
                  loading='lazy'
                  placeholderStyle={{ backgroundColor: 'lightgray' }}
                />
              )}
            </div>
            <div className='col-xs-6'>
              {image2 && (
                <Img
                  fluid={getFluidImage({
                    cloudinary: image2.public_id,
                    ar: 1.7
                  })}
                  alt={image2.meta?.description || 'map list cover'}
                  title={image2.meta?.description}
                  loading='lazy'
                  placeholderStyle={{ backgroundColor: 'lightgray' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapList
