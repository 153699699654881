import React from "react"
import { Link } from "gatsby"

import { chunkArray, getComponentClass, getComponentId } from "../utils/helpers"

const OurLocations = ({ settings, pageContext }) => {
  const { buttons } = settings
  const { langSlug } = pageContext

  const rows = chunkArray(buttons, 3)

  const componentId = getComponentId(settings)

  const locations = rows.flatMap(row =>
    row.map(({ value: { title, url, address, postalCodeAndCity } }) => ({
      title,
      url,
      address,
      postalCodeAndCity,
    }))
  )

  return (
    <div
      id={componentId}
      className={`our-locations bg-white ${getComponentClass(settings)}`}
    >
      <h2>Unsere Standorte im Überblick</h2>

      <div className="our-locations-list">
        {locations.map(({ title, url, address, postalCodeAndCity }, index) => {
          return (
            <Link key={index} to={url}>
              <div className="our-locations-item">
                <p>{title}</p>
                <p>{address}</p>
                <p>{postalCodeAndCity}</p>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default OurLocations
