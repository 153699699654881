import React from 'react'
import { getComponentClass, getComponentId } from '../utils/helpers'
// import { Link } from 'gatsby'

const HTML = ({ settings }) => {
  const { html } = settings
  const componentId = getComponentId(settings)

  return (
    <div
      id={componentId}
      className={`html ${getComponentClass(settings)}`}
      style={{ padding: '2rem' }}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default HTML
