import React from 'react'
import { Link } from 'gatsby'

import ThemeContext from '../context/ThemeContext'
import { chunkArray, getComponentClass, getComponentId } from '../utils/helpers'

const ButtonGroup = ({ settings, pageContext }) => {
  const { buttons } = settings
  const { langSlug } = pageContext

  const rows = chunkArray(buttons, 3)

  const componentId = getComponentId(settings)

  return (
    <ThemeContext.Consumer>
      {color => (
        <div
          id={componentId}
          className={`button-group ${getComponentClass(settings)}`}
        >
          {rows.map((row, index) => (
            <div className='row equal mobile-content-wrapper' key={index}>
              {row.map((button, index) => {
                const title = button.value?.title
                const url = button.value?.url

                return (
                  <div className='col-sm-4' key={index}>
                    <Link to={`/${langSlug}${url}`}>
                      <div
                        className='button-group-item'
                        style={{ color, borderColor: color }}
                      >
                        {title}
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default ButtonGroup
